import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '@/store'

Vue.use(VueRouter)

const routes = [
	{
		path: '/signin',
		component: () => import('@/views/SignIn'),
	},
	{
		path: '',
		component: () => import('@/layouts/BaseLayout'),
		meta: { requiresAuth: true },
		props: {
			paths: [
				// { text: '員工', value: '/staffs' },
				// { text: '分店', value: '/shops' },
				// { text: '區域', value: '/areas' },
				{ text: '菜單', value: '/catalog' },
				{ text: '銷售報告', value: '/salesReport' },
			],
		},
		children: [
			{ path: '/staffs', component: () => import('@/views/StaffList') },
			{ path: '/staffs/:id', component: () => import('@/views/StaffDetail') },
			{ path: '/shops', component: () => import('@/views/ShopList') },
			{ path: '/shops/:id', component: () => import('@/views/ShopDetail') },
			{ path: '/areas', component: () => import('@/views/AreaList') },
			{ path: '/areas/:id', component: () => import('@/views/AreaDetail') },
			{ path: '/catalog', component: () => import('@/views/CatalogList') },
			{ path: '/catalog/extra/:id', component: () => import('@/views/ExtraDetail') },
			{ path: '/catalog/product/:id', component: () => import('@/views/ProductDetail') },
			{ path: '/catalog/category/:id', component: () => import('@/views/CategoryDetail') },
			{ path: '/catalog/menu/:id', component: () => import('@/views/MenuDetail') },
			// 銷售報告頁面
            {path: '/salesReport',component: () => import('@/views/SalesReport.vue'),},
		],
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

router.beforeEach((to, from, next) => {
	if (!to.matched.some(record => record.meta.requiresAuth)) return next()
	if (Store.getters.authState) return next()
	next({ path: '/signin' })
})

export default router
